import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginTop: theme.spacing(10),
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(6)
    }
  },

  contentContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2
  },
  gifContainer: {
    position: 'relative',

    width: '100%',
    height: '100%'
  },

  gifBackground: {
    width: '100%',
    height: '100%'
  },
  gif: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    zIndex: 1,
    width: '65%',
    borderRadius: 16
  },

  title: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      fontSize: 50,
      lineHeight: '52px'
    }
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  sparkleContainer: {
    position: 'absolute',
    left: '10%',
    top: '45%',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
      top: '30%',
      left: '80%'
    }
  },
  bottomHeader: {
    marginTop: theme.spacing(15),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(6)
    }
  },
  mintButton: {
    marginTop: theme.spacing(3)
  },
  bbcLogo: {
    position: 'absolute',
    bottom: '-10%',
    left: '50%',
    transform: 'translate(-50%,0)',
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      bottom: '-15%'
    }
  }
}));
