import { styled } from '@material-ui/core';
import { Outlet } from 'react-router-dom';

const MainLayoutRoot = styled('div')(({ theme }) => ({
  // backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  // overflow: 'hidden',
  width: '100%'
}));

export const MainLayout = () => (
  <MainLayoutRoot>
    <div id="back-to-top-anchor" />
    <Outlet />

    {/* <Footer /> */}
  </MainLayoutRoot>
);
