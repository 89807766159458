import { Container, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { ReactComponent as ArrowRight } from 'src/assets/svgs/ArrowRight.svg';
import { MintBatsButton } from 'src/components/mint-bats-btn';
import { Sparkles } from 'src/components/sparkles';
import { useStyles } from './story.styles';

export const Story = () => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Container maxWidth="lg" className={classes.container}>
      <Sparkles className={classes.sparkleContainer} />
      <ArrowRight className={classes.arrowRight} />
      <div className={classes.innerContainer}>
        <div className={classes.textContainer}>
          {/* <img src="" alt="Our Story Logo"/> */}
          <Typography
            component="h2"
            variant="h2"
            align="center"
            className={classes.title}
          >
            Our Story
          </Typography>
          <Typography component="p" variant="body1" gutterBottom>
            Billionaire Bats Club launched on October 30th, 2021 with an
            exclusive focus on utility and the metaverse. Our team members are
            Solana advocates from the UK and USA.
          </Typography>
          <Typography
            component="p"
            variant="body1"
            style={{ marginBottom: theme.spacing(3) }}
          >
            7500 unique bats and 50% royalty share with all of our holders as a
            way to say thanks for buying our project with an airdrop scheduled
            to all holders.
          </Typography>
          <Typography
            component="p"
            variant="body1"
            style={{ marginBottom: theme.spacing(3) }}
          >
            Too many projects miss the point of NFTs and the opportunity it
            creates which is why we decided to launch on Solana, a newer
            blockchain with less transaction fees and much more room for growth.
            Stay tuned for announcements as we will gradually be releasing
            utility for all Billionaire Bat Club holders. You can find our
            roadmap at the bottom of this page!
          </Typography>
          <MintBatsButton />
        </div>
      </div>
    </Container>
  );
};
