import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(12)
  },
  phaseCard: {
    border: `2px solid ${theme.palette.text.primary}`,
    borderRadius: 16,
    maxWidth: 640,
    width: '100%',
    padding: theme.spacing(6)
  },
  phaseTitle: {
    marginBottom: theme.spacing(2)
  },
  text: {
    marginBottom: theme.spacing(2)
  },
  sparkleContainer: {
    position: 'absolute',
    right: '5%',
    bottom: '10%',
    transform: 'rotate(180deg)',

    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  title: {
    marginBottom: theme.spacing(6)
  }
}));
