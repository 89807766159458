export const Typography = {
  h1: {
    fontWeight: 500,
    fontSize: 80,
    // letterSpacing: '-0.24px',
    lineHeight: '96px'
  },
  h2: {
    fontWeight: 500,
    fontSize: 48,
    lineHeight: '52px'
    // letterSpacing: '-0.24px'
    // font: normal normal bold 48px/96px Montserrat;
  },
  h3: {
    // letterSpacing: '-0.06px',
    fontSize: 36,
    lineHeight: '48px',
    fontWeight: 500
  },
  h4: {
    fontWeight: 500,
    fontSize: 26,
    letterSpacing: '-0.06px'
  },
  h5: {
    fontWeight: 500,
    fontSize: 16,
    letterSpacing: '-0.05px'
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: '-0.05px'
  },
  overline: {
    fontWeight: 500
  },
  body1: {
    fontSize: 18,
    lineHeight: '32px',
    letterSpacing: '-0.05px'
  },
  fontFamily: [
    'Montserrat',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif'
  ].join(',')
};
