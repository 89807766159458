import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(12),
    position: 'relative'
  },

  gifContainer: {
    position: 'relative',

    width: '100%',
    minWidth: 390,
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      minWidth: 300
    },
    '&:hover': {
      transform: 'matrix(1, -0.09, 0.09, 1, 0, 0)'
    }
  },

  gifBackground: {
    width: '100%',
    height: '100%'
  },
  gif: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    zIndex: 1,
    width: '65%',
    borderRadius: 16
  }
}));
