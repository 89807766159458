import { makeStyles } from '@material-ui/core';
import StoryBackgroundSVG from 'src/assets/svgs/Path_218.svg';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(12),
    position: 'relative',
    overflowX: 'hidden'
  },

  innerContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    backgroundImage: `url("${StoryBackgroundSVG}")`,
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',

    backgroundPosition: 'center',
    padding: `${theme.spacing(15)}px 0`
  },

  textBackground: {
    position: 'absolute',
    width: '100%',
    maxWidth: 800,
    height: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)'
  },
  textContainer: {
    position: 'relative',
    width: '80%',
    height: 'auto',
    borderRadius: 16,
    backgroundColor: theme.palette.background.default,
    border: '2px solid #0D0D0D',
    padding: theme.spacing(6),
    paddingTop: theme.spacing(12),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2)
    }
  },
  title: {
    marginBottom: theme.spacing(3)
  },
  sparkleContainer: {
    position: 'absolute',
    left: '-9%',
    top: '47%',
    zIndex: -1
  },
  arrowRight: {
    position: 'absolute',
    top: '-26%',
    right: '3%',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      top: '-16%',
      height: 300
    }
  }
}));
