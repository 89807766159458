import { Container, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Sparkles } from 'src/components/sparkles';
import { useStyles } from './roadmap.styles';

export const Roadmap = () => {
  const classes = useStyles();

  const roadmap = [
    {
      phase: 'Phase 1',
      title: 'Launch',
      date: '',
      description: (
        <>
          <Typography
            color="textPrimary"
            variant="body1"
            component="p"
            gutterBottom
          >
            We’ll be launching 7,500 bats to fly high into the moonlit skies of
            Solana.
          </Typography>
          <ul>
            <Typography color="textPrimary" component="li" variant="body1">
              Pre-Sale – 28th October 1 SOL PER BAT [1500 BATS]
            </Typography>
            <Typography
              color="textPrimary"
              component="li"
              variant="body1"
              gutterBottom
            >
              Public Sale – 30th October 1 SOL PER BAT [6000 BATS]
            </Typography>
          </ul>
          <Typography color="textPrimary" variant="body1" component="p">
            150 people who mint a bat from public sale will win 5 SOL each!
          </Typography>
        </>
      )
    },
    {
      phase: 'Phase 2',
      title: 'Post mint',
      date: '',
      description: (
        <ul>
          <Typography
            color="textPrimary"
            component="li"
            variant="body1"
            gutterBottom
          >
            Charity - As part of our long term commitment to give back to the
            community, as soon as all bats are minted we’re going to be making a
            donation that will help build a Centre of Excellence in Abuja,
            Nigeria.
          </Typography>
          <Typography
            color="textPrimary"
            component="li"
            variant="body1"
            gutterBottom
          >
            Marketplace - We’re listed with Magic Eden and SolSea so you can
            trade bats in the secondary market.
          </Typography>
          <Typography
            color="textPrimary"
            component="li"
            variant="body1"
            gutterBottom
          >
            Royalties - 50% Royalty Share distributed monthly to holders.
          </Typography>
          <Typography color="textPrimary" component="li" variant="body1">
            Airdrops - Within the first Month of successful launch we will be
            airdropping 1 for 1 another NFT to the holders of Bats which will
            have a separate utility to the bats. This NFT will be a companion to
            the bats.
          </Typography>
        </ul>
      )
    },
    {
      phase: 'Phase 3',
      title: 'Future Utility',
      date: 'Q1 2022 and beyond',
      description: (
        <ul>
          <Typography
            color="textPrimary"
            component="li"
            variant="body1"
            gutterBottom
          >
            BatFest – We will be holding an event similar to Guttercon which was
            a first of it’s kind where the blockchain meets real life! All
            holders of bats will get free entrance and complimentary access to
            all BatFest activities, and the DAO will vote on the first two
            locations. We have team members both in the UK, USA and Africa to
            make this possible.
          </Typography>
          <Typography color="textPrimary" component="li" variant="body1">
            Further metaverse utility – we will aim to continue providing
            utility whether that be via airdrops or project collaboration with
            other artists for our community. You can rest assured holding a bat
            will be worth its weight in gold!
          </Typography>
        </ul>
      )
    }
  ];

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Sparkles className={classes.sparkleContainer} />
      <Typography
        color="textPrimary"
        component="h2"
        variant="h2"
        align="center"
        className={classes.title}
      >
        It All Begins With an Idea
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {roadmap.map((phase, idx) => (
          <Grid item key={idx}>
            <div className={classes.phaseCard}>
              <Typography
                color="textPrimary"
                variant="h4"
                component="h4"
                className={classes.phaseTitle}
              >
                {phase.phase} - {phase.title} {phase.date && ' - ' + phase.date}
              </Typography>
              {phase.description}
            </div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
