import { Navigate } from 'react-router-dom';
import { MainLayout } from 'src/components/main-layout';
import { HomeContainer } from 'src/pages/home';

export const routes = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '/', element: <HomeContainer /> },
      { path: '*', element: <Navigate to="/" /> }
    ]
  }
];
