import { createTheme, Fab, ThemeProvider } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  ConnectionProvider,
  WalletProvider
} from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet
} from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';
import React, { useMemo, useReducer } from 'react';
import { useRoutes } from 'react-router-dom';
import { GlobalStyles } from 'src/components/GlobalStyles';
import { routes } from 'src/routes';
import { ScrollTop } from './components/scroll-top';
import { GlobalAppContext } from './store/global-app-context';
import { initialState, reducer } from './store/reducer';
import { Typography } from './theme/typography';

// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css');


const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

export const App = () => {
  const routing = useRoutes(routes);
  const [state, dispatch] = useReducer(reducer, initialState);

  const theme: any = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: state.isDarkMode ? 'dark' : 'light',
          background: {
            default: '#ffffff',

            paper: state.isDarkMode ? '#000000' : '#ffffff'
          },
          primary: {
            // contrastText: '#ffffff',
            main: '#6657f6'
          },
          secondary: {
            main: '#7AFFEB'
          }
          // text: {
          //   primary: '#172b4d',
          //   secondary: '#6b778c'
          // }
        },
        overrides: {
          MuiDrawer: {
            paper: {
              backgroundColor: state.isDarkMode ? '#212121' : '#fff'
            }
          },
          MuiButton: {
            label: {
              textTransform: 'none'
            },
            root: {
              borderRadius: 38,
              border: '2px solid #0D0D0D',
              fontSize: 21,
              lineHeight: '29px',
              padding: '12px 16px'
            },

            contained: {
              '&:disabled': {
                backgroundColor: 'gray',
                opacity: 0.5,
                color: 'white',
                cursor: 'not-allowed'
              }
            }
          },
          MuiTypography: {
            gutterBottom: {
              marginBottom: '1rem'
            }
          }
        },
        typography: Typography
      }),
    [state.isDarkMode]
  );

  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network })
    ],
    []
  );

  return (
    <GlobalAppContext.Provider value={{ state, dispatch }}>
      <ThemeProvider theme={theme}>
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect={true}>
            <WalletModalProvider>
              <GlobalStyles />
              {routing}
              <ScrollTop>
                <Fab
                  color="primary"
                  size="medium"
                  aria-label="scroll back to top"
                >
                  <KeyboardArrowUpIcon />
                </Fab>
              </ScrollTop>
            </WalletModalProvider>
          </WalletProvider>
        </ConnectionProvider>
      </ThemeProvider>
    </GlobalAppContext.Provider>
  );
};
