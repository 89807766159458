import { Container, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { ReactComponent as ArrowRight } from 'src/assets/svgs/ArrowRight.svg';
import { Sparkles } from 'src/components/sparkles';
import { useStyles } from './team.styles';

export const Team = () => {
  const classes = useStyles();
  const team = [
    {
      name: 'Mitch / g4lvz',
      title: 'Project Founder',
      description:
        'Mitch started in NFT’s on Ethereum and first explored Solana NFTs with a purchase of a SMB. He then continued to enjoy and expand his Solana projects and did so heavily investing in Piggy Sol Gang.',
      image:
        'https://res.cloudinary.com/dpaucaa0u/image/upload/v1643942684/BillionaireBats/g4lvz.png'
    },
    {
      name: 'Jamie',
      title: '',
      description:
        'Jamie got into NFTs from the success of his IRL friend Mitch. He saw that Mitch changed his life from a few wise investments on ERC NFTs and wanted to get involved in the community aspect. Jamie is in charge of the Bat Community and ensuring that everything is transparent and that we are delivering on our promises.',
      image:
        'https://res.cloudinary.com/dpaucaa0u/image/upload/v1643942684/BillionaireBats/Jamie.png'
    },
    {
      name: 'D3wyan',
      title: '',
      description:
        'D3wyan is an artist from Sri Lanka that has been working with Mitch in business. D3wyan realized that with his talent he could create some really special art and really change his life via the means of NFTs. Mitch and D3wyan put together a plan to not only help D3wyan but also other third world countries as well as our community.',
      image:
        'https://res.cloudinary.com/dpaucaa0u/image/upload/v1643942684/BillionaireBats/D3wyan.png'
    },
    {
      name: 'n8',
      title: 'Lead Adviser',
      description:
        'n8 first started in crypto in 2013, and now works in the space full-time as a Product Manager at Chainlink Labs. He joins the team in an advisor role because of his passion for connecting people and helping them realize their vision.',
      image:
        'https://res.cloudinary.com/dpaucaa0u/image/upload/v1643942684/BillionaireBats/n8.png'
    },
    {
      name: 'Cameron / Anime hater',
      title: 'Full-Stack Engineer',
      description:
        'CornCobb is an experienced Software Engineer with +3 years of development experience. He is currently a Full-Stack Software Developer at California based startup but also does contract work as a medium to learn new technologies. He is heavily involved with web-based contract work designing and building full-stack web apps and building crypto projects.',
      image:
        'https://res.cloudinary.com/dpaucaa0u/image/upload/v1643938993/profile-pics/profile_pic_2.jpg'
    },
    {
      name: 'Mrinal / chknslaya',
      title: '',
      description:
        'Mrinal has been working for the Empire for the past many years and broke into crypto because of his good friend n8. Nowadays he primarily focuses on minting dreams into reality and has several side habits he needs to fund.',
      image:
        'https://res.cloudinary.com/dpaucaa0u/image/upload/v1644286700/BillionaireBats/mrinal.jpg'
    }
  ];
  return (
    <Container maxWidth="lg" className={classes.container}>
      <Sparkles className={classes.sparkleContainer} />
      <ArrowRight className={classes.arrowRight} />

      <div className={classes.wrapperContainer}>
        <Grid container direction="column" className={classes.innerContainer}>
          <Grid item>
            <Typography
              component="h2"
              variant="h2"
              align="center"
              color="textPrimary"
              className={classes.header}
            >
              The Team
            </Typography>
          </Grid>
          <Grid item className={classes.membersContainer}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={3}
            >
              {team.map((member, idx) => (
                <Grid item key={idx} className={classes.imageContainer}>
                  <img
                    draggable={false}
                    src={member.image}
                    alt={member.name}
                    className={classes.image}
                  />
                  <div className={classes.textContainer}>
                    <Typography
                      component="p"
                      variant="subtitle1"
                      color="textPrimary"
                      className={classes.name}
                    >
                      {member.name}
                    </Typography>
                    <Typography
                      component="p"
                      variant="caption"
                      color="secondary"
                      className={classes.title}
                      gutterBottom
                    >
                      {member.title}
                    </Typography>
                    <Typography
                      component="p"
                      variant="body2"
                      color="textPrimary"
                      className={classes.description}
                    >
                      {member.description}
                    </Typography>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};
