import { makeStyles } from '@material-ui/core';
import TeamBackgroundSVG from 'src/assets/svgs/Path_218.svg';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',

    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(12),
    overflowX: 'hidden'
  },

  textBackground: {
    width: '100%',
    height: '100%'
  },
  wrapperContainer: {
    position: 'relative',

    padding: theme.spacing(6),

    backgroundImage: `url("${TeamBackgroundSVG}")`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',

    backgroundPosition: 'center'
  },
  innerContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `${theme.spacing(15)}px 0`
  },
  imageContainer: {
    position: 'relative',
    '&:hover div': {
      visibility: 'visible',
      opacity: 1,
      transition: 'opacity 0.3s ease-in-out'
    }
  },
  image: {
    width: 240,
    height: 360,
    objectFit: 'cover',
    overflow: 'hidden',
    border: '2px solid #0D0D0D',
    boxShadow: '0px 3px 20px #00000029',
    borderRadius: 16,
    position: 'relative'
  },
  textContainer: {
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',

    visibility: 'hidden',
    position: 'absolute',

    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    zIndex: 1,
    backgroundColor: 'black',
    padding: theme.spacing(2),
    boxShadow: '0px 3px 20px #00000029',

    width: 240,
    height: 360,
    border: '2px solid #0D0D0D',
    borderRadius: 16
  },
  membersContainer: {
    width: '80%',
    margin: 'auto'
  },
  description: {
    WebkitLineClamp: 12,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    overflow: 'hidden',
    hyphens: 'auto',
    WebkitHyphens: 'auto'
  },
  header: {
    marginBottom: theme.spacing(3)
  },
  name: {
    marginBottom: '0.3rem'
  },
  title: {
    color: 'gray'
  },
  sparkleContainer: {
    position: 'absolute',
    right: '-8%',
    transform: 'rotate(90deg)',
    top: '50%',
    zIndex: -1
  },
  arrowRight: {
    position: 'absolute',
    top: '-10%',
    left: '3%',
    transform: 'scaleX(-1)',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      top: '-7%',
      height: 300
    }
  }
}));
