import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%'
  },
  topBar: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    position: 'fixed',
    top: 0,
    zIndex: 5
  }
}));
