import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(12),
    position: 'relative',
    overflowX: 'hidden'
  },
  logo: {
    width: '100%'
  },
  buttonContainer: {
    margin: `${theme.spacing(3)}px auto`
  }
}));
