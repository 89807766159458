import { Box, Button, makeStyles, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import * as anchor from '@project-serum/anchor';
import { useAnchorWallet } from '@solana/wallet-adapter-react';
import React, { useContext, useEffect, useState } from 'react';
import { GlobalAppContext } from 'src/store/global-app-context';
import { ActionTypes } from 'src/store/reducer';
import {
  awaitTransactionSignatureConfirmation,
  CandyMachine,
  getCandyMachineState,
  mintOneToken
} from '../candy-machine';

export const useStyles = makeStyles((theme) => ({}));

interface AlertState {
  open: boolean;
  message: string;
  severity: 'success' | 'info' | 'warning' | 'error' | undefined;
}

const treasury = new anchor.web3.PublicKey(
  process.env.REACT_APP_TREASURY_ADDRESS!
);

const config = new anchor.web3.PublicKey(
  process.env.REACT_APP_CANDY_MACHINE_CONFIG!
);

const candyMachineId = new anchor.web3.PublicKey(
  process.env.REACT_APP_CANDY_MACHINE_ID!
);

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

export const LiveMintBatsButton = (props: any) => {
  const classes = useStyles();
  const [balance, setBalance] = useState<number>();

  // console.log('REACT_APP_CANDY_MACHINE_CONFIG', config.toBase58());
  // console.log('candyMachineId', candyMachineId.toBase58());
  // console.log('treasury', treasury.toBase58());

  const { state, dispatch } = useContext(GlobalAppContext);

  const [enableMint, setEnableMint] = useState<boolean>(false);
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: '',
    severity: undefined
  });
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);
  const [walletHidden, setWalletHidden] = useState<boolean>(false);

  const wallet = useAnchorWallet();

  useEffect(() => {
    if (wallet?.publicKey) setEnableMint(true);
    else setEnableMint(false);
  }, [wallet?.publicKey]);

  const handleMint = async () => {
    if (!wallet?.publicKey) return console.error('Wallet not connected');
    try {
      setIsMinting(true);
      dispatch({ type: ActionTypes.SET_DISABLE_BUTTON, payload: true });

      if (wallet && candyMachine?.program) {
        console.log('here');
        const mintTxId = await mintOneToken(
          candyMachine,
          config,
          wallet.publicKey,
          // ownerWallet,
          treasury
        );

        console.log('asdf');

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          txTimeout,
          connection,
          'singleGossip',
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: 'Congratulations! Mint succeeded!',
            severity: 'success'
          });
        } else {
          console.error(status?.err);
          setAlertState({
            open: true,
            message: `Mint failed! ${status?.err}`,
            severity: 'error'
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:

      let message = error.msg || 'Minting failed! Please try again!';
      if (!error.msg) {
        if (error.message.indexOf('0x138')) {
        } else if (error.message.indexOf('0x137')) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf('0x135')) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: 'error'
      });
    } finally {
      dispatch({ type: ActionTypes.SET_DISABLE_BUTTON, payload: false });

      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const { candyMachine, itemsAvailable, itemsRemaining, itemsRedeemed } =
        await getCandyMachineState(
          wallet as anchor.Wallet,
          candyMachineId,
          connection
        );

      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);
      // setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  };

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection
  ]);

  const renderButtonText = () => {
    if (isMinting) return 'Minting...';
    if (isSoldOut) return 'Sold Out';
    return 'Mint The Bats';
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        disabled={!enableMint || isSoldOut || isMinting || state.disableButton}
        onClick={handleMint}
        {...props}
      >
        {renderButtonText()}
        {' ->'}
      </Button>
      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
      {/* Loader */}
      {isMinting && (
        <Box
          style={{
            width: '100%',
            position: 'fixed',
            bottom: 0,
            left: 0,
            zIndex: 99
          }}
        >
          <LinearProgress color="primary" />
        </Box>
      )}
    </>
  );
};
