import React from 'react';
import { ReactComponent as LargeSparkle } from 'src/assets/svgs/LargeSparkle.svg';
import { ReactComponent as SmallSparkle } from 'src/assets/svgs/SmallSparkle.svg';

export const Sparkles = (props: any) => {
  return (
    <div {...props}>
      <LargeSparkle />
      <SmallSparkle />
    </div>
  );
};
