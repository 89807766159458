import { Bottom, Collections, Roadmap, Story, Team, Top } from './components';
import { useStyles } from './home.styles';

export const HomeLayout = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {/* <div className={classes.topBar}>
        <CountDown />
      </div> */}
      <Top />
      <Story />
      <Collections />
      <Team />
      <Roadmap />
      <Bottom />
    </div>
  );
};
