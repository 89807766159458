export interface IState {
  isDarkMode: boolean;
  isWalletConnected: boolean;
  userAddress: string | null;
  showConnectWalletDialog: boolean;
  disableButton: boolean;
}

export const initialState: IState = {
  isDarkMode: true,
  isWalletConnected: false,
  userAddress: null,
  showConnectWalletDialog: false,
  disableButton: false
};

export enum ActionTypes {
  SET_IS_DARK_MODE = 'SET_IS_DARK_MODE',
  SET_IS_WALLET_CONNECTED = 'SET_IS_WALLET_CONNECTED',
  SET_USER_ADDRESS = 'SET_USER_ADDRESS',
  SET_SHOW_CONNECT_WALLET_DIALOG = 'SET_SHOW_CONNECT_WALLET_DIALOG',
  SET_DISABLE_BUTTON = 'SET_DISABLE_BUTTON'
}

interface ISetIsDarkMode {
  type: ActionTypes.SET_IS_DARK_MODE;
  payload: boolean;
}

interface ISetIsWalletConnected {
  type: ActionTypes.SET_IS_WALLET_CONNECTED;
  payload: boolean;
}

interface ISetShowConnectWalletDialog {
  type: ActionTypes.SET_SHOW_CONNECT_WALLET_DIALOG;
  payload: boolean;
}

interface ISetUserAddress {
  type: ActionTypes.SET_USER_ADDRESS;
  payload: string | null;
}

interface ISetDisableButton {
  type: ActionTypes.SET_DISABLE_BUTTON;
  payload: boolean;
}

export type ComponentActions =
  | ISetIsDarkMode
  | ISetIsWalletConnected
  | ISetShowConnectWalletDialog
  | ISetUserAddress | ISetDisableButton;

export const reducer: React.Reducer<IState, ComponentActions> = (
  state,
  action
) => {
  switch (action.type) {
    case ActionTypes.SET_IS_DARK_MODE:
      return {
        ...state,
        isDarkMode: action.payload
      };
    case ActionTypes.SET_IS_WALLET_CONNECTED:
      return { ...state, isWalletConnected: action.payload };

    case ActionTypes.SET_USER_ADDRESS:
      return { ...state, userAddress: action.payload };
    case ActionTypes.SET_SHOW_CONNECT_WALLET_DIALOG:
      return { ...state, showConnectWalletDialog: action.payload };
    case ActionTypes.SET_DISABLE_BUTTON:
      return { ...state, disableButton: action.payload };
    default:
      return state;
  }
};
