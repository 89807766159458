import { Container, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { ReactComponent as GifBackgroundSVG } from 'src/assets/svgs/Path_212.svg';
import { useStyles } from './collections.styles';

export const Collections = () => {
  const classes = useStyles();

  const batImages = [
    'https://res.cloudinary.com/dpaucaa0u/image/upload/v1644286673/BillionaireBats/Bat_3.png',
    'https://res.cloudinary.com/dpaucaa0u/image/upload/v1644286673/BillionaireBats/Bat_8.png',
    'https://res.cloudinary.com/dpaucaa0u/image/upload/v1644286691/BillionaireBats/nNr_JO7o.png',
    'https://res.cloudinary.com/dpaucaa0u/image/upload/v1644286736/BillionaireBats/Bat_1886.png',
    'https://res.cloudinary.com/dpaucaa0u/image/upload/v1644286737/BillionaireBats/Bat_714.png',
    'https://res.cloudinary.com/dpaucaa0u/image/upload/v1644286737/BillionaireBats/Bat_6569.png'
  ];

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography
        component="h2"
        variant="h2"
        align="center"
        color="textPrimary"
      >
        The Collections
      </Typography>

      <Grid container justifyContent="center" alignItems="center">
        {batImages.map((bat, idx) => (
          <Grid item key={idx}>
            <div className={classes.gifContainer}>
              <GifBackgroundSVG className={classes.gifBackground} />
              <img
                className={classes.gif}
                alt="bat"
                src={bat}
                draggable={false}
              />
            </div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
